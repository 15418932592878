<template>
  <div>
    <div class="person_info">
      <div class="left" @click="goUser(item.metaId, item.zeroAddress)">
        <img :src="item.metaId | avatarUrl" alt="" class="avatar">
        <div>
          <div>{{ item.userName }}</div>
          <div>MetaID: {{ item.metaId.substr(0, 6) }}</div>
        </div>
      </div>
      <div class="right">
        <template v-if="item.metaId !== showId">
          <div class="followed" v-if="item.isFollow" @click="followTa(item)">
            <div></div>
          </div>
          <div class="follow" v-else @click="followTa(item)">{{$t('lang.follow')}}</div>
        </template>
        <img src="../../../assets/img/index/icon_more.png" alt="" class="icon_more" @click="openMorePopup(item.metaId)">
      </div>
    </div>
    <van-swipe>
      <van-swipe-item v-for="attachment in item.attachments" :key="attachment">
        <div style="font-size: 0">
          <template v-if='attachment.lastIndexOf(".mp4") > -1'>
            <video :src="attachment | videoFilters" :poster="getPoster(attachment)" controls
                   webkit-playsinline="true" playsinline="true" x5-playsinline="true" object-fit="contain" style="width: 100%; height: 400px" />
          </template>
          <template v-else>
            <van-image :src="attachment | fileUrl" fit="cover" alt="" width="100%" height="100%" @click="viewPic(item.attachments, attachment)" />
          </template>
        </div>
      </van-swipe-item>
    </van-swipe>
    <template v-if="item.attachments && item.attachments.length">
      <operate :like="item.like" :comment="item.comment" :donate="item.donate" @like_click="likeTa(item)" @comment_click="comment(item.txId, item.metaId, item.zeroAddress)" @donate_click="donate(item.txId, item.metaId, item.zeroAddress)"></operate>
      <div class="content" v-if="item.content">
        <span style="color: #666666">{{ item.userName }}：</span>
        <span>{{ item.content }}</span>
      </div>
    </template>
    <template v-else>
      <div class="content" v-if="item.content">
        <span style="color: #666666">{{ item.userName }}：</span>
        <span>{{ item.content }}</span>
      </div>
      <operate :like="item.like" :comment="item.comment" :donate="item.donate" @like_click="likeTa(item)" @comment_click="comment(item.txId, item.metaId, item.zeroAddress)" @donate_click="donate(item.txId, item.metaId, item.zeroAddress)"></operate>
    </template>
    <template v-if="item.comment.length">
      <div class="comment_count" @click="comment(item.txId, item.metaId, item.zeroAddress)">{{ $t('lang.totalComment', [item.comment.length]) }}</div>
      <div class="comment_list">
        <template v-for="commentItem in item.comment.slice(0, 2)" v-if="$route.path === '/index'">
          <span style="color: #666666">{{ commentItem.userName }}：</span>{{ commentItem.content }}<br />
        </template>
        <template v-for="commentItem in commentList" v-if="$route.path !== '/index'">
          <span style="color: #666666">{{ commentItem.userName }}：</span>{{ commentItem.content }}<br />
        </template>
      </div>
    </template>
    <div class="datetime">{{new Date(item.timestamp).format('MM-dd hh:mm:ss')}}</div>
    <van-popup v-model="showMore" round class="more_popup">
      <div @click="openBlacklistPopup" v-show="item.metaId !== showId">{{ $t('lang.addToBlacklist') }}</div>
<!--      <div @click="noLike">不感兴趣</div>-->
<!--      <div @click="openSharePopup">分享到...</div>-->
      <div @click="openNotify(item.txId)">{{ $t('lang.copyLink') }}</div>
      <div @click="viewTX(item.txId)">{{ $t('lang.checkTX') }}</div>
      <div @click="closeMorePopup">{{ $t('lang.cancel') }}</div>
    </van-popup>
    <van-popup v-model="showBlacklist" round position="bottom" class="blacklist_popup">
      <div class="info">
        <img src="../../../assets/img/index/icon_blacklist.png" alt="">
        <div @click="blackList">
          <div>{{ $t('lang.addToBlacklist') }}</div>
          <div>{{ $t('lang.blacklistTips') }}</div>
        </div>
      </div>
      <div class="close" @click="showBlacklist=false">
        {{ $t('lang.cancel') }}
      </div>
    </van-popup>
    <van-popup v-model="showShare" round position="bottom" class="share_popup">
      <div>分享到...</div>
      <div>
        <img src="../../../assets/img/index/icon_weibo.png" alt="">
        <span>分享到 微博</span>
      </div>
      <div>
        <img src="../../../assets/img/index/icon_timeline.png" alt="">
        <span>分享到 朋友圈</span>
      </div>
      <div @click="showShare=false">
        <img src="../../../assets/img/index/icon_cancel.png" alt="">
        <span>取消</span>
      </div>
    </van-popup>
    <van-popup v-model="showComment" round position="bottom" class="comment_popup" @close="closeCommentPopup">
      <div class="content">
        {{ $t('lang.allComments') }} {{commentList.length}}
      </div>
      <template v-if="commentList && commentList.length">
        <div v-for="(item, index) in commentList" :key="index" class="comment_item">
          <img class="avatar" :src="item.metaId | avatarUrl" alt="">
          <div style="display: block">
            <div class="name">{{item.userName}}<span class="tx" @click="viewTX(item.metaId)">tx</span></div>
            <div class="comment_content">{{item.content}}</div>
<!--            <div class="reply">{{ $t('lang.reply') }}</div>-->
          </div>
          <span :style="{color: item.hasMyLike ? '#FF5858' : '#BFC2CC'}">{{item.likeCount}}</span>
          <img class="like" :src="require(`../../../assets/img/index/comment/${item.hasMyLike ? 'icon_liked' : 'icon_like'}.png`)" alt="" @click="likeComment(item)">
        </div>
        <div style="display: flex; padding: 20px">
          <van-field v-model="replyContent" :border="false" :placeholder="$t('lang.leaveYourComment')" style="flex: 1" />
          <van-button type="info" @click="commentTa(item)">{{ $t('lang.share') }}</van-button>
        </div>
      </template>
      <div class="nodata" v-else>
        <img src="../../../assets/img/findtime/default_icon_coment.svg" alt="">
        <span class="c_tips">{{ $t('lang.noCommentYet') }}</span>
        <div style="display: flex; padding: 20px; position: absolute; bottom: 0; left: 0; right: 0">
          <van-field v-model="replyContent" :border="false" :placeholder="$t('lang.leaveYourComment')" style="flex: 1" />
          <van-button type="info" @click="commentTa(item)">{{ $t('lang.share') }}</van-button>
        </div>
      </div>
    </van-popup>
    <van-popup v-model="showDonate" class="donate_popup" closeable>
      <div style="padding: 20px 20px 10px">{{ $t('lang.tip') }}</div>
      <div style="display: flex; flex-direction: column; align-items: center; padding: 25px 25px 30px;" v-if="toggle">
        <div style="display: flex; flex-wrap: wrap; justify-content: center;">
          <div v-for="amountItem in amountList"
               class="thousand"
               @click="handleDonate(item, amountItem.tipAmount)"
          >
            <div>{{ amountItem.amount }}</div>
            <div>{{ amountItem.unit }}</div>
          </div>
        </div>
        <div style="color: #999; cursor: pointer" @click="toggle=false;tipAmount=''">{{ $t('lang.otherAmount') }}</div>
      </div>
      <div style="display: flex; flex-direction: column; align-items: center; padding: 25px 25px 30px;" v-else>
        <div style="margin-bottom: 16px; display: flex; justify-content: center; align-items: center">
          <span>Sat</span>
          <van-field :placeholder="$t('lang.enterTheAmount')" v-model="tipAmount" type="digit"></van-field>
        </div>
        <div style="color: #999; cursor: pointer" @click="toggle=true">{{ $t('lang.fixedAmount') }}</div>
        <div class="confirmBtn" @click="handleDonate(item, tipAmount)">{{ $t('lang.ok') }}</div>
      </div>
    </van-popup>
    <van-notify v-model="showNotify" type="success" className="notifyHeight" color="#FFFFFF" background="rgba(0, 0, 0, 0.9)" :message="$t('lang.linkCopied')">
    </van-notify>
  </div>
</template>

<script>
import {getMyFollow} from "api/api";
import common from '@/utils/common'
import Operate from "./Operate";
import {Field, ImagePreview, Popup} from 'vant'
import {getBuzzInteractiveList} from 'api/api'

export default {
  name: "ContentItem",
  data() {
    return {
      showMore: false,
      showBlacklist: false,
      showShare: false,
      showNotify: false,
      showComment: false,
      commentList: [],
      replyContent: '',
      showDonate: false,
      followData: {},
      toggle: true,
      showId: localStorage.getItem('showId'),
      metaId: '',
      amountList: [
          {amount: '10K', unit: 'Sat', tipAmount: 10000},
          {amount: '50K', unit: 'Sat', tipAmount: 50000},
          {amount: '200K', unit: 'Sat', tipAmount: 200000},
          {amount: '0.01', unit: 'bsv', tipAmount: 1000000},
          {amount: '0.05', unit: 'bsv', tipAmount: 5000000},
          {amount: '0.2', unit: 'bsv', tipAmount: 20000000}
      ],
      tipAmount: ''
    }
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    [Popup.name]: Popup,
    [Field.name]: Field,
    Operate
  },
  computed: {
    getPoster () {
      return (value) => {
        if (!value) return ''
        return `${process.env.VUE_APP_AppImgApi}/metafile/videoImg/${value.replace('metafile://', '')}`
      }
    }
  },
  mounted() {
    if (this.$route.path === '/square/detail') { // 广场详情
      getBuzzInteractiveList({
        buzzTxId: this.item.txId,
        metaId: localStorage.getItem('showId'),
        page: "1",
        pageSize: "500",
        protocols: ["PayComment"],
        timestamp: 0
      }).then(res => {
        this.commentList = res.data.results.items
      })
    }
  },
  methods: {
    openMorePopup(metaId) {
      this.metaId = metaId
      this.showMore = true
    },
    closeMorePopup() {
      this.showMore = false
    },
    openBlacklistPopup() {
      this.showMore = false
      this.showBlacklist = true
    },
    openSharePopup() {
      // this.showMore = false
      // this.showShare = true
      this.$toast(this.$t('lang.comingSoon'))
    },
    openNotify (txId) {
      // Notify({
      //   message: '链接已复制到剪贴板',
      //   color: '#FFFFFF',
      //   background: '#000000',
      //   className: 'notifyHeight'
      // })
      this.showMore = false
      let href = location.origin + '/square/detail?buzzTxId=' + txId
      this.$copyText(href).then(res => {
        this.showNotify = true
      })
      setTimeout(() => {
        this.showNotify = false
      }, 3000)
    },
    likeTa(item) {
      if (!this.$store.state.userInfo) {
        this.$toast(this.$t('lang.loginTips'))
        return
      }
      let params = {
        "createTime": +new Date(),
        "MetaID": item.metaId,
        "isLike": 1,
        "likeTo": item.txId,
        "pay": 2000,
        "payTo": item.zeroAddress
      }

      const txData = common.getTxData({
        nodeName: 'PayLike',
        brfcId: 'b4a118f94cf2',
        path: '/Protocols/PayLike',
        data: JSON.stringify(params),
        attachments: [],
        payTo: [
          {
            amount: 2000,
            address: item.zeroAddress
          },
          {
            amount: 500,
            address: process.env.VUE_APP_PlatformAddress
          }
        ]
      })

      this.$store.state.sdk.sendMetaDataTx(txData).then(res=>{
        item.like.push({
          metaId: params.MetaID,
          timestamp: params.createTime,
          txId: params.likeTo,
          userName: '',
          value: params.pay
        })
        this.$toast(this.$t('lang.success'))
      }, (err) => {

      })
    },
    followTa(item) {
      if (!this.$store.state.userInfo) {
        this.$toast(this.$t('lang.loginTips'))
        return
      }
      let params = {
        "createTime": +new Date(),
        "MetaID": item.metaId,
        "status": item.isFollow ? -1 : 1, // 1添加关注，-1取消关注
        "pay": 2000,
        "payTo": item.zeroAddress
      }
      debugger

      const txData = common.getTxData({
        nodeName: 'PayFollow',
        brfcId: '8f23b51a047b',
        path: '/Protocols/PayFollow',
        data: JSON.stringify(params),
        attachments: [],
        payTo: [
          {
            amount: 2000,
            address: item.zeroAddress
          }
        ]
      })

      this.$store.state.sdk.sendMetaDataTx(txData).then(res=>{
        item.isFollow = !item.isFollow
        this.$toast(this.$t('lang.success'))
      }, (err) => {

      }).then(res => {
        getMyFollow({
          metaId: localStorage.getItem('showId')
        }).then(res => {
          this.followData = res.data
          localStorage.setItem('followData', JSON.stringify(res.data))
        })
      })
    },
    blackList() {
      if (!this.$store.state.userInfo) {
        this.$toast(this.$t('lang.loginTips'))
        return
      }
      let params = {
        "createTime": +new Date(),
        "MetaID": this.metaId,
        "status": 1 // 1加入黑名单 -1从黑名单去除
      }

      const txData = common.getTxData({
        nodeName: 'SimpleBlacklist',
        brfcId: 'a8e689f8af1c',
        path: '/Protocols/SimpleBlacklist',
        data: JSON.stringify(params),
        payTo: [] // 平台方和作者都不用收取
      })

      this.$store.state.sdk.sendMetaDataTx(txData).then(res=>{
        this.showBlacklist = false
      }, (err) => {

      })
    },
    noLike() {
      this.$toast(this.$t('lang.comingSoon'))
    },
    comment(txId, metaId, zeroAddress) {
      this.txId = txId
      this.metaId = metaId
      this.zeroAddress = zeroAddress
      this.showComment = true
      let params = {
        buzzTxId: this.txId,
        metaId: localStorage.getItem('showId'),
        page: "1",
        pageSize: "500",
        protocols: ["PayComment"],
        timestamp: 0
      }
      getBuzzInteractiveList(params).then(res => {
        this.commentList = res.data.results.items
      })
    },
    donate(txId, metaId, zeroAddress) {
      this.txId = txId
      this.metaId = metaId
      this.zeroAddress = zeroAddress
      this.showDonate = true
    },
    handleDonate(item, amount) {
      if (!amount) {
        return
      }

      this.tipAmount = amount

      if (!this.$store.state.userInfo) {
        this.$toast(this.$t('lang.loginTips'))
        return
      }
      let params = {
        "createTime": +new Date(),
        "recipientID": this.metaId,
        "recipientAddress": this.zeroAddress,
        "amount": parseInt(this.tipAmount), // 单位聪
        "targetID": this.txId, // 打赏的txId
        "isDonated": 1,
        "message": ""
      }

      const txData = common.getTxData({
        nodeName: 'SimpleArticleDonate',
        brfcId: 'b03ee4b37f32',
        path: '/Protocols/SimpleArticleDonate',
        data: JSON.stringify(params),
        attachments: [],
        payTo: [
          {
            amount: parseInt(this.tipAmount),
            address: this.zeroAddress
          }
        ]
      })

      this.$store.state.sdk.sendMetaDataTx(txData).then(res=>{
        item.donate.push({
          metaId: params.recipientID,
          timestamp: params.createTime,
          txId: params.targetID,
          userName: '',
          value: params.amount
        })
        this.$toast(this.$t('lang.success'))
        this.showDonate = false
      }, (err) => {

      })
    },
    closeCommentPopup() {
      this.showComment = false
    },
    commentTa(item) {
      if (!this.$store.state.userInfo) {
        this.$toast(this.$t('lang.loginTips'))
        return
      }
      if (!this.replyContent) {
        this.$toast(this.$t('lang.emptyContent'))
        return
      }
      debugger
      let params = {
        "createTime": +new Date(),
        "content": this.replyContent,
        "contentType": "text/plain",
        "commentTo": this.txId,
        "pay": 2000,
        "payTo": this.metaId
      }

      const txData = common.getTxData({
        nodeName: 'PayComment',
        brfcId: '70b8224e0559',
        path: '/Protocols/PayComment',
        data: JSON.stringify(params),
        attachments: [],
        payTo: [
          {
            amount: 2000,
            address: this.zeroAddress
          },
          {
            amount: 500,
            address: process.env.VUE_APP_PlatformAddress
          }
        ]
      })

      this.$store.state.sdk.sendMetaDataTx(txData).then(res=>{
        this.commentList.push({
          metaId: this.$store.state.userInfo.metaId,
          txId: res.data.txId,
          userName: this.$store.state.userInfo.name,
          content: this.replyContent,
          likeCount: 0,
          hasMyLike: false
        })
        item.comment.push({
          content: params.content,
          metaId: params.payTo,
          timestamp: params.createTime,
          txId: params.commentTo,
          userName: this.$store.state.userInfo.name,
          value: params.pay
        })
        this.replyContent = ''
        this.$toast(this.$t('lang.success'))
      }, (err) => {

      })
    },
    viewTX(txId) {
      location.href = `https://whatsonchain.com/tx/${txId}`
    },
    viewPic(attachments, attachment) {
      ImagePreview({
        images: attachments.map(res => `${process.env.VUE_APP_AppImgApi}/metafile/${res.replace("metafile://", "").toLowerCase()}`),
        startPosition: attachments.indexOf(attachment),
        closeable: true
      })
    },
    goUser(metaId, zeroAddress) {
      this.$router.push({
        name: 'User2',
        params: {
          txId: metaId
        }
      })
      this.$store.commit('updateOtherUserInfo', {
        metaId,
        zeroAddress
      })
    },
    likeComment(item) {
      if (item.hasMyLike) return

      let params = {
        "createTime": +new Date(),
        "MetaID": item.metaId,
        "isLike": 1,
        "likeTo": item.txId,
        "pay": 2000,
        "payTo": item.zeroAddress
      }

      const txData = common.getTxData({
        nodeName: 'PayLike',
        brfcId: 'b4a118f94cf2',
        path: '/Protocols/PayLike',
        data: JSON.stringify(params),
        attachments: [],
        payTo: [
          {
            amount: 2000,
            address: item.zeroAddress
          },
          {
            amount: 500,
            address: process.env.VUE_APP_PlatformAddress
          }
        ]
      })

      this.$store.state.sdk.sendMetaDataTx(txData).then(res=>{
        this.$toast(this.$t('lang.success'))
      }, (err) => {

      })
    }
  },
  filters: {
    fileUrl(tx) {
      if (tx.indexOf("metafile://") != -1) {
        return `${process.env.VUE_APP_AppImgApi}/metafile/${tx.replace("metafile://", "compress640/").toLowerCase()}`
      } else {
        return `${process.env.VUE_APP_AppImgApi}/metafile/compress640/${tx}`
      }
    },
    avatarUrl(avatarTx){
      if(avatarTx){
        return `${process.env.VUE_APP_AppImgApi}/metafile/avatar/${avatarTx}`
      }else{
        return `${process.env.VUE_APP_AppImgApi}/metafile/avatar/undefined`
      }
    },
    videoFilters(value) {
      if (!value) return ''
      value = value.substring(0, value.lastIndexOf(".")) // 去除后缀
      return `${process.env.VUE_APP_AppImgApi}/metafile/${value.replace('metafile://', '')}`
    },
  }
}
</script>

<style lang="less" scoped>
.person_info {
  height: 50px;
  padding: 0 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .left {
    display: flex;
    align-items: center;
    cursor: pointer;
    .avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
      background-color: #CCCCCC;
    }
    > div {
      margin-left: 10px;
      > div:first-child {
        color: #000000;
        font-size: 14px;
      }
      > div:last-child {
        color: #BFC2CC;
        font-size: 10px;
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    cursor: pointer;
    .followed {
      background-color: #FFFFFF;
      width: 48px;
      height: 22px;
      border-radius: 12px;
      border: 1Px solid #617FFF;
      display: flex;
      justify-content: center;
      margin-right: 8px;
      div {
        width: 5px;
        height: 13px;
        border-color: #617FFF;
        border-style: solid;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }
    }
    .follow {
      background-color: #617FFF;
      width: 48px;
      height: 22px;
      border-radius: 12px;
      border: 1Px solid #617FFF;
      color: #FFFFFF;
      text-align: center;
      line-height: 24px;
      margin-right: 8px;
    }
    .icon_more {
      width: 20px;
      height: 20px;
    }
  }
}
.content {
  margin: 0 18px 24px;
  font-size: 14px;
  font-weight: bold;
  line-height: 24px;
}
.comment_count {
  margin-left: 18px;
  color: #BFC2CC;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}
.comment_list {
  margin: 12px 18px 20px;
  font-size: 14px;
  line-height: 24px;
  color: #303133;
}
.datetime {
  margin: 0 18px 24px;
  color: #8e8e8e;
}
.more_popup {
  div {
    width: 260px;
    height: 50px;
    font-size: 14px;
    color: #313131;
    text-align: center;
    line-height: 50px;
    border: 1px solid #E3E2E2;
    cursor: pointer;
    &:first-child {
      color: #FF4D4D;
    }
  }
}
.blacklist_popup {
  .info {
    display: flex;
    align-items: center;
    margin: 25px 30px 0 30px;
    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    > div {
      color: #CCCCCC;
      font-size: 14px;
      div:nth-child(1) {
        color: #313131;
        font-size: 18px;
      }
    }
  }
  .close {
    height: 50px;
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    color: #303133;
    background-color: #EDEFF2;
    margin: 17px 20px 34px;
  }
}
.share_popup {
  padding-bottom: 41px;
  > div:first-child {
    margin: 13px 0 31px;
    text-align: center;
    font-size: 18px;
    color: #313131;
  }
  > div:not(:nth-child(1)) {
    height: 50px;
    display: flex;
    align-items: center;
    margin-left: 20px;
    img {
      width: 22px;
      height: 22px;
      margin-right: 10px;
    }
    span {
      color: #303133;
      font-size: 16px;
    }
  }
}
.comment_popup {
  .content {
    padding: 20px 20px 0px;
  }
  .comment_item {
    display: flex;
    align-items: center;
    padding: 2px 20px;
    .avatar {
      width: 36px;
      height: 36px;
      border-radius: 50%;
    }
    .like {
      width: 22px;
      height: 22px;
      cursor: pointer;
    }
    > div {
      margin-left: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 1;
      .name {
        height: 17px;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        color: #909399;
        .tx {
          margin-left: 6px;
          padding: 2px 4px;
          border-radius: 4px;
          background: #e3f6fc;
          color: #2391ef;
          font-weight: 500;
          cursor: pointer;
        }
      }
      .comment_content {
        height: auto;
        min-height: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #303133;
        margin-top: 3px;
      }
      .reply {
        width: auto;
        height: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        color: #909399;
        margin-top: 8px;
      }
    }
  }
  .nodata {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 101px 0 185px 0;
    img {
      width: 30px;
      height: 30px;
    }
    .c_tips {
      font-size: 14px;
      font-weight: 400;
      color: #bfc2cc;
    }
  }
}
.donate_popup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 360px;
  .thousand {
    color: #fc6d5e;
    border: 1px solid #fc6d5e;
    margin: 0 16px 16px 0;
    width: 78px;
    height: 50px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
  }
  .confirmBtn {
    margin-top: 43px;
    color: #fc6d5e;
    cursor: pointer;
  }
}
.notifyHeight {
  height: 60px;
}
</style>