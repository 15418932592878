var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "operate" }, [
    _c("div", { on: { click: _vm.handleLikeClick } }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/img/index/" + _vm.likePic + ".png"),
          alt: "",
        },
      }),
      _c(
        "span",
        { style: { color: _vm.likePic === "icon_like_act" ? "red" : "" } },
        [_vm._v(_vm._s(_vm.like_count_c))]
      ),
    ]),
    _c("div", { on: { click: _vm.handleCommentClick } }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/img/index/icon_comment.png"),
          alt: "",
        },
      }),
      _c("span", [
        _vm._v(_vm._s(_vm.comment.length || _vm.$t("lang.comment"))),
      ]),
    ]),
    _c("div", { on: { click: _vm.handleDonateClick } }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/img/index/" + _vm.donatePic + ".png"),
          alt: "",
        },
      }),
      _c(
        "span",
        { style: { color: _vm.donatePic === "icon_bitcoin_act" ? "red" : "" } },
        [
          _vm._v(
            _vm._s(
              _vm.donate.length
                ? _vm.donate_amount_c + " Sat"
                : 0 || _vm.$t("lang.tip")
            )
          ),
        ]
      ),
    ]),
    _c("div", { on: { click: _vm.fav } }, [
      _c("img", {
        attrs: {
          src: require("../../../assets/img/index/icon_fav.png"),
          alt: "",
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }