var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "person_info" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function ($event) {
                return _vm.goUser(_vm.item.metaId, _vm.item.zeroAddress)
              },
            },
          },
          [
            _c("img", {
              staticClass: "avatar",
              attrs: { src: _vm._f("avatarUrl")(_vm.item.metaId), alt: "" },
            }),
            _c("div", [
              _c("div", [_vm._v(_vm._s(_vm.item.userName))]),
              _c("div", [
                _vm._v("MetaID: " + _vm._s(_vm.item.metaId.substr(0, 6))),
              ]),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _vm.item.metaId !== _vm.showId
              ? [
                  _vm.item.isFollow
                    ? _c(
                        "div",
                        {
                          staticClass: "followed",
                          on: {
                            click: function ($event) {
                              return _vm.followTa(_vm.item)
                            },
                          },
                        },
                        [_c("div")]
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "follow",
                          on: {
                            click: function ($event) {
                              return _vm.followTa(_vm.item)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("lang.follow")))]
                      ),
                ]
              : _vm._e(),
            _c("img", {
              staticClass: "icon_more",
              attrs: {
                src: require("../../../assets/img/index/icon_more.png"),
                alt: "",
              },
              on: {
                click: function ($event) {
                  return _vm.openMorePopup(_vm.item.metaId)
                },
              },
            }),
          ],
          2
        ),
      ]),
      _c(
        "van-swipe",
        _vm._l(_vm.item.attachments, function (attachment) {
          return _c("van-swipe-item", { key: attachment }, [
            _c(
              "div",
              { staticStyle: { "font-size": "0" } },
              [
                attachment.lastIndexOf(".mp4") > -1
                  ? [
                      _c("video", {
                        staticStyle: { width: "100%", height: "400px" },
                        attrs: {
                          src: _vm._f("videoFilters")(attachment),
                          poster: _vm.getPoster(attachment),
                          controls: "",
                          "webkit-playsinline": "true",
                          playsinline: "true",
                          "x5-playsinline": "true",
                          "object-fit": "contain",
                        },
                      }),
                    ]
                  : [
                      _c("van-image", {
                        attrs: {
                          src: _vm._f("fileUrl")(attachment),
                          fit: "cover",
                          alt: "",
                          width: "100%",
                          height: "100%",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.viewPic(_vm.item.attachments, attachment)
                          },
                        },
                      }),
                    ],
              ],
              2
            ),
          ])
        }),
        1
      ),
      _vm.item.attachments && _vm.item.attachments.length
        ? [
            _c("operate", {
              attrs: {
                like: _vm.item.like,
                comment: _vm.item.comment,
                donate: _vm.item.donate,
              },
              on: {
                like_click: function ($event) {
                  return _vm.likeTa(_vm.item)
                },
                comment_click: function ($event) {
                  return _vm.comment(
                    _vm.item.txId,
                    _vm.item.metaId,
                    _vm.item.zeroAddress
                  )
                },
                donate_click: function ($event) {
                  return _vm.donate(
                    _vm.item.txId,
                    _vm.item.metaId,
                    _vm.item.zeroAddress
                  )
                },
              },
            }),
            _vm.item.content
              ? _c("div", { staticClass: "content" }, [
                  _c("span", { staticStyle: { color: "#666666" } }, [
                    _vm._v(_vm._s(_vm.item.userName) + "："),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.item.content))]),
                ])
              : _vm._e(),
          ]
        : [
            _vm.item.content
              ? _c("div", { staticClass: "content" }, [
                  _c("span", { staticStyle: { color: "#666666" } }, [
                    _vm._v(_vm._s(_vm.item.userName) + "："),
                  ]),
                  _c("span", [_vm._v(_vm._s(_vm.item.content))]),
                ])
              : _vm._e(),
            _c("operate", {
              attrs: {
                like: _vm.item.like,
                comment: _vm.item.comment,
                donate: _vm.item.donate,
              },
              on: {
                like_click: function ($event) {
                  return _vm.likeTa(_vm.item)
                },
                comment_click: function ($event) {
                  return _vm.comment(
                    _vm.item.txId,
                    _vm.item.metaId,
                    _vm.item.zeroAddress
                  )
                },
                donate_click: function ($event) {
                  return _vm.donate(
                    _vm.item.txId,
                    _vm.item.metaId,
                    _vm.item.zeroAddress
                  )
                },
              },
            }),
          ],
      _vm.item.comment.length
        ? [
            _c(
              "div",
              {
                staticClass: "comment_count",
                on: {
                  click: function ($event) {
                    return _vm.comment(
                      _vm.item.txId,
                      _vm.item.metaId,
                      _vm.item.zeroAddress
                    )
                  },
                },
              },
              [
                _vm._v(
                  _vm._s(_vm.$t("lang.totalComment", [_vm.item.comment.length]))
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "comment_list" },
              [
                _vm._l(_vm.item.comment.slice(0, 2), function (commentItem) {
                  return _vm.$route.path === "/index"
                    ? [
                        _c("span", { staticStyle: { color: "#666666" } }, [
                          _vm._v(_vm._s(commentItem.userName) + "："),
                        ]),
                        _vm._v(_vm._s(commentItem.content)),
                        _c("br"),
                      ]
                    : _vm._e()
                }),
                _vm._l(_vm.commentList, function (commentItem) {
                  return _vm.$route.path !== "/index"
                    ? [
                        _c("span", { staticStyle: { color: "#666666" } }, [
                          _vm._v(_vm._s(commentItem.userName) + "："),
                        ]),
                        _vm._v(_vm._s(commentItem.content)),
                        _c("br"),
                      ]
                    : _vm._e()
                }),
              ],
              2
            ),
          ]
        : _vm._e(),
      _c("div", { staticClass: "datetime" }, [
        _vm._v(_vm._s(new Date(_vm.item.timestamp).format("MM-dd hh:mm:ss"))),
      ]),
      _c(
        "van-popup",
        {
          staticClass: "more_popup",
          attrs: { round: "" },
          model: {
            value: _vm.showMore,
            callback: function ($$v) {
              _vm.showMore = $$v
            },
            expression: "showMore",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.item.metaId !== _vm.showId,
                  expression: "item.metaId !== showId",
                },
              ],
              on: { click: _vm.openBlacklistPopup },
            },
            [_vm._v(_vm._s(_vm.$t("lang.addToBlacklist")))]
          ),
          _c(
            "div",
            {
              on: {
                click: function ($event) {
                  return _vm.openNotify(_vm.item.txId)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang.copyLink")))]
          ),
          _c(
            "div",
            {
              on: {
                click: function ($event) {
                  return _vm.viewTX(_vm.item.txId)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("lang.checkTX")))]
          ),
          _c("div", { on: { click: _vm.closeMorePopup } }, [
            _vm._v(_vm._s(_vm.$t("lang.cancel"))),
          ]),
        ]
      ),
      _c(
        "van-popup",
        {
          staticClass: "blacklist_popup",
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.showBlacklist,
            callback: function ($$v) {
              _vm.showBlacklist = $$v
            },
            expression: "showBlacklist",
          },
        },
        [
          _c("div", { staticClass: "info" }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/img/index/icon_blacklist.png"),
                alt: "",
              },
            }),
            _c("div", { on: { click: _vm.blackList } }, [
              _c("div", [_vm._v(_vm._s(_vm.$t("lang.addToBlacklist")))]),
              _c("div", [_vm._v(_vm._s(_vm.$t("lang.blacklistTips")))]),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "close",
              on: {
                click: function ($event) {
                  _vm.showBlacklist = false
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("lang.cancel")) + " ")]
          ),
        ]
      ),
      _c(
        "van-popup",
        {
          staticClass: "share_popup",
          attrs: { round: "", position: "bottom" },
          model: {
            value: _vm.showShare,
            callback: function ($$v) {
              _vm.showShare = $$v
            },
            expression: "showShare",
          },
        },
        [
          _c("div", [_vm._v("分享到...")]),
          _c("div", [
            _c("img", {
              attrs: {
                src: require("../../../assets/img/index/icon_weibo.png"),
                alt: "",
              },
            }),
            _c("span", [_vm._v("分享到 微博")]),
          ]),
          _c("div", [
            _c("img", {
              attrs: {
                src: require("../../../assets/img/index/icon_timeline.png"),
                alt: "",
              },
            }),
            _c("span", [_vm._v("分享到 朋友圈")]),
          ]),
          _c(
            "div",
            {
              on: {
                click: function ($event) {
                  _vm.showShare = false
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("../../../assets/img/index/icon_cancel.png"),
                  alt: "",
                },
              }),
              _c("span", [_vm._v("取消")]),
            ]
          ),
        ]
      ),
      _c(
        "van-popup",
        {
          staticClass: "comment_popup",
          attrs: { round: "", position: "bottom" },
          on: { close: _vm.closeCommentPopup },
          model: {
            value: _vm.showComment,
            callback: function ($$v) {
              _vm.showComment = $$v
            },
            expression: "showComment",
          },
        },
        [
          _c("div", { staticClass: "content" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("lang.allComments")) +
                " " +
                _vm._s(_vm.commentList.length) +
                " "
            ),
          ]),
          _vm.commentList && _vm.commentList.length
            ? [
                _vm._l(_vm.commentList, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "comment_item" },
                    [
                      _c("img", {
                        staticClass: "avatar",
                        attrs: {
                          src: _vm._f("avatarUrl")(item.metaId),
                          alt: "",
                        },
                      }),
                      _c("div", { staticStyle: { display: "block" } }, [
                        _c("div", { staticClass: "name" }, [
                          _vm._v(_vm._s(item.userName)),
                          _c(
                            "span",
                            {
                              staticClass: "tx",
                              on: {
                                click: function ($event) {
                                  return _vm.viewTX(item.metaId)
                                },
                              },
                            },
                            [_vm._v("tx")]
                          ),
                        ]),
                        _c("div", { staticClass: "comment_content" }, [
                          _vm._v(_vm._s(item.content)),
                        ]),
                      ]),
                      _c(
                        "span",
                        {
                          style: {
                            color: item.hasMyLike ? "#FF5858" : "#BFC2CC",
                          },
                        },
                        [_vm._v(_vm._s(item.likeCount))]
                      ),
                      _c("img", {
                        staticClass: "like",
                        attrs: {
                          src: require("../../../assets/img/index/comment/" +
                            (item.hasMyLike ? "icon_liked" : "icon_like") +
                            ".png"),
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.likeComment(item)
                          },
                        },
                      }),
                    ]
                  )
                }),
                _c(
                  "div",
                  { staticStyle: { display: "flex", padding: "20px" } },
                  [
                    _c("van-field", {
                      staticStyle: { flex: "1" },
                      attrs: {
                        border: false,
                        placeholder: _vm.$t("lang.leaveYourComment"),
                      },
                      model: {
                        value: _vm.replyContent,
                        callback: function ($$v) {
                          _vm.replyContent = $$v
                        },
                        expression: "replyContent",
                      },
                    }),
                    _c(
                      "van-button",
                      {
                        attrs: { type: "info" },
                        on: {
                          click: function ($event) {
                            return _vm.commentTa(_vm.item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("lang.share")))]
                    ),
                  ],
                  1
                ),
              ]
            : _c("div", { staticClass: "nodata" }, [
                _c("img", {
                  attrs: {
                    src: require("../../../assets/img/findtime/default_icon_coment.svg"),
                    alt: "",
                  },
                }),
                _c("span", { staticClass: "c_tips" }, [
                  _vm._v(_vm._s(_vm.$t("lang.noCommentYet"))),
                ]),
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      padding: "20px",
                      position: "absolute",
                      bottom: "0",
                      left: "0",
                      right: "0",
                    },
                  },
                  [
                    _c("van-field", {
                      staticStyle: { flex: "1" },
                      attrs: {
                        border: false,
                        placeholder: _vm.$t("lang.leaveYourComment"),
                      },
                      model: {
                        value: _vm.replyContent,
                        callback: function ($$v) {
                          _vm.replyContent = $$v
                        },
                        expression: "replyContent",
                      },
                    }),
                    _c(
                      "van-button",
                      {
                        attrs: { type: "info" },
                        on: {
                          click: function ($event) {
                            return _vm.commentTa(_vm.item)
                          },
                        },
                      },
                      [_vm._v(_vm._s(_vm.$t("lang.share")))]
                    ),
                  ],
                  1
                ),
              ]),
        ],
        2
      ),
      _c(
        "van-popup",
        {
          staticClass: "donate_popup",
          attrs: { closeable: "" },
          model: {
            value: _vm.showDonate,
            callback: function ($$v) {
              _vm.showDonate = $$v
            },
            expression: "showDonate",
          },
        },
        [
          _c("div", { staticStyle: { padding: "20px 20px 10px" } }, [
            _vm._v(_vm._s(_vm.$t("lang.tip"))),
          ]),
          _vm.toggle
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    "align-items": "center",
                    padding: "25px 25px 30px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "flex-wrap": "wrap",
                        "justify-content": "center",
                      },
                    },
                    _vm._l(_vm.amountList, function (amountItem) {
                      return _c(
                        "div",
                        {
                          staticClass: "thousand",
                          on: {
                            click: function ($event) {
                              return _vm.handleDonate(
                                _vm.item,
                                amountItem.tipAmount
                              )
                            },
                          },
                        },
                        [
                          _c("div", [_vm._v(_vm._s(amountItem.amount))]),
                          _c("div", [_vm._v(_vm._s(amountItem.unit))]),
                        ]
                      )
                    }),
                    0
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { color: "#999", cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          _vm.toggle = false
                          _vm.tipAmount = ""
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("lang.otherAmount")))]
                  ),
                ]
              )
            : _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "flex-direction": "column",
                    "align-items": "center",
                    padding: "25px 25px 30px",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-bottom": "16px",
                        display: "flex",
                        "justify-content": "center",
                        "align-items": "center",
                      },
                    },
                    [
                      _c("span", [_vm._v("Sat")]),
                      _c("van-field", {
                        attrs: {
                          placeholder: _vm.$t("lang.enterTheAmount"),
                          type: "digit",
                        },
                        model: {
                          value: _vm.tipAmount,
                          callback: function ($$v) {
                            _vm.tipAmount = $$v
                          },
                          expression: "tipAmount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { color: "#999", cursor: "pointer" },
                      on: {
                        click: function ($event) {
                          _vm.toggle = true
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("lang.fixedAmount")))]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "confirmBtn",
                      on: {
                        click: function ($event) {
                          return _vm.handleDonate(_vm.item, _vm.tipAmount)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("lang.ok")))]
                  ),
                ]
              ),
        ]
      ),
      _c("van-notify", {
        attrs: {
          type: "success",
          className: "notifyHeight",
          color: "#FFFFFF",
          background: "rgba(0, 0, 0, 0.9)",
          message: _vm.$t("lang.linkCopied"),
        },
        model: {
          value: _vm.showNotify,
          callback: function ($$v) {
            _vm.showNotify = $$v
          },
          expression: "showNotify",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }